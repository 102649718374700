import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Countdown from 'react-countdown';

const NewSignupForm = ({ handleOnChange, emailExist, phoneNumberExist, submitForm, ...props }) => {
  const { t } = useTranslation();
  const [enableOTPButton, setEnableOTPButton] = useState(false);
  const [timer, setTimer] = useState(Date.now() + 59000);
  const [refCode, setRefCode] = useState("");

  useEffect(() => {
    // Function to handle referral code extraction
    const updateRefCode = () => {
      const url = window.location.href;
      const parts = url.split("/");
      const possibleRefCode = parts[parts.length - 1]; // Last part of the URL

      // Check if the possibleRefCode is valid (e.g., not the page name)
      if (possibleRefCode && possibleRefCode !== "NewSignup") {
        setRefCode(possibleRefCode); // Set referral code
        handleOnChange({ target: { name: "refCode", value: possibleRefCode } }); // Update form state
      }
    };

    // Run the function when the component mounts or the URL changes
    updateRefCode();

    // Listen for URL changes if using hash routing
    window.addEventListener("popstate", updateRefCode);

    return () => {
      window.removeEventListener("popstate", updateRefCode);
    };
  }, [window.location.href]); // Re-run when the URL changes


  const renderer = ({ hours, minutes, seconds, completed }) => {
    return <span><strong>00 : {seconds}</strong></span>;
  };

  return (
    <>
      <div className="logo"><a href="/"><img src="images/dark_logo.png" alt="img" /></a></div>
      <form onSubmit={submitForm} className="form-space">
        {props.otpScreen ? (
          <div className="form-group">
            <label>Enter OTP which is sent on email</label>
            <input
              type="text"
              className="form-control"
              name="otp"
              onChange={handleOnChange}
              required
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                style={{ marginTop: 10 }}
                type="submit"
                className="blue_btn btn"
                defaultValue="Login"
              >
                Submit
              </button>
              <div style={{ marginTop: 10, display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                {!enableOTPButton && <Countdown date={timer} renderer={renderer} onComplete={() => { setEnableOTPButton(true) }} />}
                <button
                  className="blue_btn btn pl-4"
                  style={{ marginLeft: 10 }}
                  disabled={!enableOTPButton}
                  onClick={(e) => { setTimer(Date.now() + 59000); setEnableOTPButton(false); submitForm(e) }}
                >
                  Resend
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="form_head_part">
              <h4>Register as a Professional</h4>
              <br />
            </div>
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control"
                name="userName"
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email Id</label>
              <input
                type="email"
                className="form-control"
                name="emailId"
                onChange={handleOnChange}
                required
              />
              {emailExist && (
                <span style={{ color: "red" }}>Email already exist</span>
              )}
            </div>
            <div className="row">
              <div className="form-group col-sm-6">
                <label>Mobile Number</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phoneNumber"
                  onChange={handleOnChange}
                  maxLength={10}
                  onKeyDown={(e) => {
                    if (!(e.key >= '0' && e.key <= '9') && e.keyCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                  required
                />
                {phoneNumberExist && (
                  <span style={{ color: "red" }}>Mobile Number already exist</span>
                )}
              </div>
              <div className="form-group col-sm-6">
                <label>Referal Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="refCode"
                  value={refCode} // Set referral code as default value
                  onChange={(e) => {
                    setRefCode(e.target.value); // Update state
                    handleOnChange(e); // Update form state
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("LoginPasswordLabel")}</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                style={{ height: 20, width: 20 }}
                type="checkbox"
                name="tnc"
                id="tnc"
                onChange={(e) =>
                  handleOnChange({
                    target: { name: "tnc", value: e.target.checked },
                  })
                }
              />{" "}
              Agree to Privacy Policy
            </div>
            <div className="btn_action_btn">
              <div className="left_side_btn">
                <button
                  type="submit"
                  className="blue_btn btn"
                  defaultValue="Login"
                  disabled={!props.tnc}
                >
                  Sign Up
                </button>
              </div>
              <p>
                Already an account?{" "}
                <Link to="/Login">
                  {" "}
                  <strong>login</strong>
                </Link>
              </p>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default NewSignupForm;

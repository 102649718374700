import React, { useEffect, useState } from 'react';
import { getAllSubscriptons } from '../../actions';

const PopUp = () => {
    const [reqList, setReqList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Define userObj in the component's scope
    const userObj = (() => {
        const user = localStorage.getItem("user");
        try {
            return user ? JSON.parse(user) : null; // Safely parse user data
        } catch (error) {
            console.error("Error parsing user data from localStorage:", error);
            return null; // Return null if parsing fails
        }
    })();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAllSubscriptons();
                console.log(response);
                setReqList(response.data.data || []);
            } catch (error) {
                console.error('Error fetching subscription plans:', error);
                setReqList([]);
            }
        };

        fetchData();
    }, []);

    return (
        <div id="premium" className="premium">
            <button
                className="btn btn-primary nextBtn btn-lg blue_btn pull-right"
                onClick={openModal}
            >
                Submit
            </button>

            {isModalOpen && (
                <div>
                    {/* Overlay for background shadow */}
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 999,
                        }}
                    ></div>
                    <div className="modal" style={{ display: 'block' }}>
                        <div className="modal-content">
                            <div className="modal-header" style={{
                                background: 'linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%)',
                            }}>
                                <button style={{ margin: "5px" }} onClick={closeModal} className="close">
                                    &times;
                                </button>
                                <h4 className="modal-title">Subscribe Now & Start Streaming</h4>
                            </div>
                            <div className="container-fluid">
                                <div className="row premium-row">
                                    {reqList.map((item, index) => (
                                        <div key={index} className="col-md-4 premium-col"
                                        style={{
                                            opacity: item.type === "Free" && userObj?.subscription !== "" ? 0.5 : 1, // Reduce opacity if the type is 'Free'
                                            
                                        }}>
                                            <div
                                                className="premiumbox"
                                                style={{
                                                    position: 'relative',
                                                    border: '1px solid #0548b3',
                                                    borderRadius: '8px',
                                                    padding: '16px',
                                                    marginBottom: '16px',
                                                    background: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        color: '#0548b3',
                                                        fontWeight: 'bold',
                                                        marginBottom: '8px',
                                                    }}
                                                >
                                                    {item.plan || 'Basic'}
                                                </h3>
                                                <hr />
                                                {/* <h4 style={{ marginBottom: '16px' }}>
                                                    Best for {item.targetAudience || 'small businesses'}
                                                </h4> */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginBottom: '14px',
                                                    }}
                                                >
                                                        
                                                        <h3 style={{ color: '#333', fontWeight: 'bold',fontSize: '30px', }}>
                                                        ₹ {item.amount || 0}
                                                    </h3>
                                                    <span style={{ fontSize: '14px', color: '#777' }}>
                                                        + GST as applicable
                                                    </span>
                                                </div>
                                                <p
                                                    style={{
                                                        fontSize: '18px',
                                                        color: '#333',
                                                        marginBottom: "15px"

                                                    }}
                                                >
                                                    Validity {item.validityInDays || 0}/days
                                                </p>
                                                <p style={{ fontSize: '12px', color: '#333', fontWeight: "bold" }}>KEY FEATURES</p>
                                                <ul className="custom-bullet-list">
                                                    <li className="custom-bullet">24/7 Support</li>
                                                    <li className="custom-bullet">Unlimited search results</li>
                                                    <li className="custom-bullet">Eligible to apply Jobs : {item.jobApplications || 'N/A'}</li>
                                                    <li className="custom-bullet">Eligible to apply Auditions : {item.auditions || 'N/A'}</li>
                                                    <li className="custom-bullet">Eligible to Hire New Talent : {item.hireTalent || 'N/A'}</li>
                                                </ul>
                                                <div className="premium_btn" style={{ marginTop: '16px' }}>
                                                    <button
                                                        type="button"
                                                        className="blue_btn"
                                                        style={{
                                                            width: '100%',
                                                            padding: '10px 0',
                                                            // backgroundColor: '#0548b3',
                                                            backgroundColor: '#c62537',
                                                            color: '#fff',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                            cursor: 'pointer',
                                                            cursor: item.type === "Free" && userObj?.subscription !== "" ? "not-allowed" : "pointer", // Disabled cursor for 'Free'

                                                        }}
                                                        onClick={() => {
                                                            if (item.type === "Free" && userObj?.subscription !== "") {
                                                                return; // Prevent navigation
                                                            }
                                                            localStorage.setItem('selectedPlan', JSON.stringify(item));
                                                            window.location.href = '#/SubscriptionDetails';
                                                        }}
                                                    >
                                                        Buy Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PopUp;

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Grid, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../core/theme/Styles";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import HomeSlider from "../../components/HomeSection/HomeSlider";
// import FlashNews from "../../components/HomeSection/FlashNews";
import MakeFilm from "../../components/HomeSection/MakeFilm";
import NewsPart from "../../components/HomeSection/newsPart/NewsPart";
import MovieStatus from "../../components/HomeSection/movieStatus/MovieStatus";
import BoxOffice from "../../components/HomeSection/boxOffice/BoxOffice";
import TopCards from "../../components/HomeSection/TopCards";
import AuditionPart from '../../components/HomeSection/auditionPart/AuditionPart';
import MidSection from '../../components/HomeSection/MidSection';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';
import { getAllSubscriptons, getCurrentSubscriptionPlan } from '../../actions';
import PopUp from '../../components/CreateUserProfile/PopUp';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.page.background.color,
  },
}));




const popularTags = [
  {
    title: 'Popular Jobs',
    link: '/SearchJob'
  },
  {
    title: 'Movie Auditions',
    link: '/SearchAudition?filmType=Feature Film'
  },
  {
    title: 'Mumbai Auditions',
    link: '/SearchAudition?location=Mumbai'
  },
  {
    title: 'Talent Management Services',
    link: '/CelebrityManagement'
  },
  {
    title: 'Auditions Near Me',
    link: '/SearchAudition'
  },
  {
    title: 'Update profile',
    link: '/CreateUserProfile'
  },
  {
    title: 'Hire Talent',
    link: '/FindTalent'
  },

  {
    title: 'Post Jobs',
    link: '/PostJob'
  },

]
function HomePage(props) {
  const history = useHistory()
  const classes = useStyles();
  const { t } = useTranslation();
  const globalStyle = globalStyles();
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);


  const onTagClick = (tag) => {
    if (localStorage.getItem('user')) {
      history.push(tag.link)
    } else {
      history.push('/login')
    }
  }

  const [loader, setLoader] = useState(true)
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  useEffect(() => {
    const fetchSubscriptionPlan = async () => {
      const user = JSON.parse(localStorage.getItem('user')); // Get user from localStorage

      if (user && user.subscription) {
        try {
          const response = await getCurrentSubscriptionPlan({ id: user.subscription }); // Call the API with the required data
          console.log('subscription plan:', response);

          if (response.data) {
            setSubscriptionPlan(response.data); // Assuming the API response contains the subscription data
            localStorage.setItem('subscriptionPlan', JSON.stringify(response.data.data)); // Store the subscription data in localStorage
          }
        } catch (error) {
          console.error('Error fetching subscription plan:', error);
        } finally {
          setLoader(false); // Stop the loader after the API call
        }
      } else {
        console.warn('User or subscription ID not found');
        setLoader(false); // Stop the loader if user is not found
      }
    };

    fetchSubscriptionPlan(); // Call the function inside useEffect
  }, []);



  const [reqList, setReqList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define userObj in the component's scope
  const userObj = (() => {
    const user = localStorage.getItem("user");
    try {
      return user ? JSON.parse(user) : null; // Safely parse user data
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
      return null; // Return null if parsing fails
    }
  })();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllSubscriptons();
        console.log(response);
        setReqList(response.data.data || []);
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
        setReqList([]);
      }
    };

    fetchData();
  }, []);


  return (
    <div>


      <div className="top_slide">
        {/* <div class="bg"></div>
<div class="bg bg2"></div>
<div class="bg bg3"></div> */}
        <Header />
        <HomeSlider />
        <TopCards />
      </div>
      <section id="trusted_parnter">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h4>Thank You</h4>
              <OwlCarousel
                className="owl-theme owl-carousel"
                loop
                items={8}
                style={{
                  opacity: 1,
                  display: 'block'
                }}
                //  {...options}
                nav={false}
                autoplay={true}
                autospeed="true"
              >
                <div className="item">
                  <img src="images/yrf.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/netflix.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/balajji.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/color_yellow.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/prime.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/red_chilli.webp" alt="" />
                </div>
                <div className="item">
                  <img src="images/sony_pro.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/zee5.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/sonyliv.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/dharma.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/Eros_International.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/mx_player.png" alt="" />
                </div>
                <div className="item">
                  <img src="images/jiocinema.png" alt="" />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <section style={{ margin: "5rem 0" }} id='feature_oppertunity1'>
        <div className="modal-header" style={{
          background: 'linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%)',
        }}>
          <h4 className="modal-title" style={{ padding: "20px" }}>Subscribe Now & Start Streaming</h4>
        </div>
        <div className="container">

          <div className="row premium-row">
            {reqList.map((item, index) => (
              <div key={index} className="col-md-4 premium-col"
                style={{
                  opacity: item.type === "Free" && userObj?.subscription !== "" ? 0.5 : 1, // Reduce opacity if the type is 'Free'

                }}>
                <div
                  className="premiumbox"
                  style={{
                    position: 'relative',
                    border: '1px solid #0548b3',
                    borderRadius: '8px',
                    padding: '16px',
                    marginBottom: '16px',
                    background: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                  }}
                >
                  <h3
                    style={{
                      color: '#0548b3',
                      fontWeight: 'bold',
                      marginBottom: '8px',
                    }}
                  >
                    {item.plan || 'Basic'} Package
                  </h3>
                  <hr />
                  {/* <h4 style={{ marginBottom: '16px' }}>
                                                    Best for {item.targetAudience || 'small businesses'}
                                                </h4> */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '14px',
                    }}
                  >

                    <h3 style={{ color: '#333', fontWeight: 'bold', fontSize: '30px', }}>
                      ₹ {item.amount || 0}
                    </h3>
                    {item.type !== "Free" &&
                      <span style={{ fontSize: '14px', color: '#777' }}>
                        + GST as applicable</span>}
                  </div>
                  {item.type === "Free" ?
                    <p style={{
                      fontSize: '18px',
                      color: '#333',
                      marginBottom: "15px"

                    }}>Validity Unlimited</p>
                    :
                    <p
                      style={{
                        fontSize: '18px',
                        color: '#333',
                        marginBottom: "15px"

                      }}
                    >
                      Validity {item.validityInDays || 0}/days
                    </p>
                  }
                  <p style={{ fontSize: '12px', color: '#333', fontWeight: "bold" }}>KEY FEATURES</p>
                  <ul className="custom-bullet-list">
                    <li className="custom-bullet">24/7 Support</li>
                    <li className="custom-bullet">Unlimited search results</li>
                    <li className="custom-bullet">Eligible to apply Jobs : {item.jobApplications || 'N/A'}</li>
                    <li className="custom-bullet">Eligible to apply Auditions : {item.auditions || 'N/A'}</li>
                    <li className="custom-bullet">Eligible to Hire New Talent : {item.hireTalent || 'N/A'}</li>
                  </ul>
                  <div className="premium_btn" style={{ marginTop: '16px' }}>
                    <button
                      type="button"
                      className="blue_btn"
                      style={{
                        width: '100%',
                        padding: '10px 0',
                        // backgroundColor: '#0548b3',
                        backgroundColor: '#c62537',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        cursor: item.type === "Free" && userObj?.subscription !== "" ? "not-allowed" : "pointer", // Disabled cursor for 'Free'

                      }}
                      onClick={() => {
                        if (item.type === "Free" && userObj?.subscription !== "") {
                          return; // Prevent navigation
                        }
                        localStorage.setItem('selectedPlan', JSON.stringify(item));
                        window.location.href = '#/SubscriptionDetails';
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="about_big">
        <div className="container">
          <div className="row">
            <div className="col-md-6 about_img">
              <img src="images/about_img1.png" alt="img" className='wow slideInLeft' />
              <img src="images/about2.png" alt="img" className='wow slideInDown' />
              <img src="images/about3.png" alt="img" className='wow slideInRight' />
            </div>
            <div class="col-md-6 head_part">
              <div className="tiltle">About Us</div>
              <h2>Welcome to Bollywood is Good</h2>
              <p><span>Unlock Your Future in Film – From Bollywood to Global Entertainment!</span></p>
              <br />
              <p>Bollywood is Good offers exclusive auditions, jobs, training, and industry connections across the entire film industry. Partnered with top production houses, OTT platforms, and studios, we bring you the best opportunities. Join us and start your journey to success today!</p>
              {/* <a href='/NewSignup' className='blue_btn'>Join Now and Launch Your Career!</a> */}
              <Link to={token ? "CreateUserProfile" : '/NewSignup'} className="blue_btn">Join Now and Launch Your Career!</Link>
              <div className="stats wow slideInRight">
                <ul>
                  <li>
                    <img src="images/talent_icon.png" alt="" />
                    <p><span>1,000+</span> Talents Placed</p>
                  </li>
                  <li>
                    <img src="images/talent_icon.png" alt="" />
                    <p><span>50+</span> OTT Partnerships</p>
                  </li>
                  <li>
                    <img src="images/talent_icon.png" alt="" />
                    <p><span>100+</span> Success Stories</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AuditionPart {...props} />

      <section id="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div class="head_part">
                <div className="tiltle">Testimonial</div>
                <h2>What Clients Says?</h2>
              </div>

              <OwlCarousel
                className="owl-theme owl-carousel"
                loop
                items={3}
                style={{
                  opacity: 1,
                  display: 'block'
                }}
                //  {...options}
                nav={false}
                autoplay={true}
                autospeed="true"
              >

                <div className="carousel-inner">
                  <div className="item active">
                    <div className="testinonial_bx">
                      <p>Bollywood is Good connected me with casting directors I would have never met otherwise. It changed my career forever.</p>
                      <div className="user_part">
                        <img src="images/men_user.png" alt="" />
                        <div className="user_text">
                          <h6>Rajiv Malhotra</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>This platform helped me turn my passion into a profession. I’m finally living my dream.</p>
                    <div className="user_part">
                      <img src="images/girl_user.png" alt="" />
                      <div className="user_text">
                        <h6>Priya Singh</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>Thanks to Bollywood is Good, I got the right exposure and connections to showcase my vision to the world.</p>
                    <div className="user_part">
                      <img src="images/men_user.png" alt="" />
                      <div className="user_text">
                        <h6>Ankit Sharma</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>The opportunities I got through Bollywood is Good were game-changers for my career behind the camera.</p>
                    <div className="user_part">
                      <img src="images/girl_user.png" alt="" />
                      <div className="user_text">
                        <h6>Sneha Kapoor</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>I owe my big break to Bollywood is Good! The platform made everything possible for me.</p>
                    <div className="user_part">
                      <img src="images/men_user.png" alt="" />
                      <div className="user_text">
                        <h6>Aarav Patel</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>Bollywood is Good gave me the chance to work on projects I could only dream of before.</p>
                    <div className="user_part">
                      <img src="images/girl_user.png" alt="" />
                      <div className="user_text">
                        <h6>Meera Joshi</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>This platform put me in touch with the right people at the right time. Now I’m finally pursuing my passion full-time.</p>
                    <div className="user_part">
                      <img src="images/men_user.png" alt="" />
                      <div className="user_text">
                        <h6>Vikram Rathi</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>Bollywood is Good opened doors for me in the film industry that I didn’t even know existed.</p>
                    <div className="user_part">
                      <img src="images/girl_user.png" alt="" />
                      <div className="user_text">
                        <h6>Nidhi Shah</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>The support and connections from Bollywood is Good were instrumental in helping me achieve my dreams.</p>
                    <div className="user_part">
                      <img src="images/men_user.png" alt="" />
                      <div className="user_text">
                        <h6>Karan Desai</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testinonial_bx">
                    <p>Thanks to this platform, I was able to showcase my writing talent to the right people and take my career to new heights.</p>
                    <div className="user_part">
                      <img src="images/girl_user.png" alt="" />
                      <div className="user_text">
                        <h6>Riya Bhardwaj</h6>
                      </div>
                    </div>
                  </div>
                </div>

              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <section id="process">
        <div className="container">
          <div className="row">
            <div class="col-md-12 head_part text-center">
              <div className="tiltle">How We Work </div>
              <h2>Your Path to Stardom in 3 Simple Steps</h2>
            </div>
            <div className="col-md-4 process_step text-center">
              <div className="process_outline">
                <img src="images/talent_icon.png" alt="" />
                <h3>Create Your Profile</h3>
                <p>Sign up and build your portfolio by showcasing your talent, skills, and experience. Whether you're an actor, director, writer, or crew member, this is your stage to shine.</p>
              </div>
            </div>
            <div className="col-md-4 process_step text-center">
              <div className="process_outline">
                <img src="images/oppertunity.png" alt="" />
                <h3>Find Opportunities</h3>
                <p>Browse through exclusive auditions, job listings, and project opportunities tailored just for you. Get matched with the roles and collaborations that align with your career goals.</p>
              </div>
            </div>
            <div className="col-md-4 process_step text-center">
              <div className="process_outline">
                <img src="images/hired.png" alt="" />
                <h3>Get Hired & Get Noticed</h3>
                <p>Apply, audition, and let us help you get placed. With our extensive industry connections, you’ll be in front of the right people, ensuring your talent gets the recognition it deserves.</p>
              </div>
            </div>
          </div>
          <div className='text-center'>
            {/* <a href='#' className='blue_btn'>Start Your Journey to Success </a> */}
            <Link to={token ? "CreateUserProfile" : '/NewSignup'} className="blue_btn">Start Your Journey to Success</Link>
          </div>
        </div>
      </section>

      <section id="spotlight">
        <div class="lines">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div className="container">
          <div className="row">
            <div class="col-md-6 head_part">
              <div className="tiltle wow slideInDown">Spotlight</div>
              <h2>OTT Spotlight: Where Talent Meets Opportunity</h2>
              <span>Your Dream, Our Mission: Enter Bollywood & OTT with Confidence</span>
              <p>Bollywood is Good is your ultimate gateway to breaking into the world of Bollywood and OTT. We are not just a platform; we are your partner in navigating the path to stardom. With exclusive access to top-tier auditions, job opportunities, and industry connections, we empower you to take the first step toward your dreams with unwavering confidence.</p>
              <Link to="/login" className="blue_btn">Get Your First Break</Link>
            </div>
            <div className="col-md-6 spot_img">
              <img className="wow slideInDown" src="images/spotlight_img.jpg" alt="img" />
              <img className="wow slideInRight" src="images/spotlight_img2.png" alt="img" />
            </div>
          </div>
        </div>
      </section>

      <section id="spotlight_list">
        <div className="container wow fadeInUpBig">
          <div className="row">
            <div className="col-md-12 ">
              <h3>Spotlight Highlights</h3>
              <ul>
                <li className="project">
                  <div class="project-hover">
                    <h4>Featured Projects</h4>
                    <hr />
                    <p>Get cast in exclusive web series, original movies, and trending shows. Explore opportunities with top OTT platforms like Netflix, Amazon Prime, Disney+, and more.</p>
                  </div>
                </li>
                <li className="project">
                  <div class="project-hover">
                    <h4>Audition Calls</h4>
                    <hr />
                    <p>Stay ahead of the curve with real-time audition announcements for leading roles and crew positions in the latest OTT productions.</p>
                  </div>
                </li>
                <li className="project">
                  <div class="project-hover">
                    <h4>Success Stories</h4>
                    <hr />
                    <p>Meet the stars who made it big through OTT platforms. Their journey started just like yours—right here!</p>
                  </div>
                </li>
                <li className="project">
                  <div class="project-hover">
                    <h4>Industry Insights</h4>
                    <hr />
                    <p>Gain insider knowledge with tips from industry experts, casting directors, and successful OTT creators. Learn what it takes to stand out in a competitive market.</p>
                  </div>
                </li>
                <li className="project">
                  <div class="project-hover">
                    <h4>Networking Events</h4>
                    <hr />
                    <p>Join exclusive online and offline events where you can connect with OTT platform executives, directors, and fellow artists. Build relationships that could be the key to your next big break.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="journey">
        <div className="container">
          <div className="row">
            <div class="col-md-7 head_part">
              <div className="tiltle">Path to Success </div>
              <h2>Your Journey with Bollywood is Good</h2>

              <ul>
                <li>
                  <h6><span class="blue_btn">1</span> Create Your Presence</h6>
                  <p>Build a standout profile showcasing your talent and portfolio. Whether you’re an actor, singer, dancer, or crew member, let the industry know who you are.</p>
                </li>
                <li>
                  <h6><span class="blue_btn">2</span> Skill Up with Expert Guidance</h6>
                  <p>Enhance your skills with our exclusive training programs, workshops, and masterclasses. Stay ahead and be prepared for every opportunity.</p>
                </li>
                <li>
                  <h6><span class="blue_btn">3</span> Access Exclusive Opportunities</h6>
                  <p>Find and apply for tailored auditions, casting calls, and job listings. From Bollywood roles to OTT projects, the perfect opportunity is waiting.</p>
                </li>
                <li>
                  <h6><span class="blue_btn">4</span> Be Discovered by Industry Leaders</h6>
                  <p>Get noticed by casting directors, producers, and production houses actively searching for new talent on our platform.</p>
                </li>
                <li>
                  <h6><span class="blue_btn">5</span> Achieve Your Big Break</h6>
                  <p>Land your first major role or project and step into the spotlight. Bollywood is Good is your gateway to stardom and career growth.</p>
                </li>
              </ul>
              {/* <a href="#" className='blue_btn'>Start Your Path to Success Today!</a> */}
              <Link to={token ? "CreateUserProfile" : '/NewSignup'} className="blue_btn">Start Your Path to Success Today!</Link>
            </div>
          </div>
        </div>
      </section>

      <section id="big_community">
        <div className="container">
          <div className="row">
            <div class="col-md-5 head_part">
              <div className="tiltle">Community</div>
              <h2>Join the BiG Community</h2>
              <h4>Connect with Like-minded Aspirants and Professionals</h4>
              <p>The journey to stardom is not one you have to take alone. At Bollywood is Good, we believe in the power of community. Our platform is home to a vibrant, supportive network of aspiring talents, industry professionals, and mentors who are all working towards the same goal: making their mark in Bollywood and OTT. Whether you’re looking to collaborate, learn, or simply share your experiences, our community is the perfect place to connect with like-minded individuals who understand your passion and drive. </p>
            </div>
            <div className="col-md-7 event_high">
              <h3>Event Highlights</h3>
              <div className="row">
                <div className="col-md-4">
                  <div className="comunity_bx">
                    <img src="images/networking_night.jpg" alt="" />
                    <h6>Networking Night</h6>
                    <p>Connect with industry professionals and fellow talents in our exclusive online and offline networking events.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="comunity_bx">
                    <img src="images/industry_workshop.jpg" alt="" />
                    <h6>Industry Workshop</h6>
                    <p>Learn from the best in the business with our expert-led workshops on acting, scriptwriting, direction, and more.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="comunity_bx">
                    <img src="images/networking.jpg" alt="" />
                    <h6>Community Challenges</h6>
                    <p>Participate in creative challenges and showcase your talent to win exciting opportunities and prizes.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
export default HomePage;
